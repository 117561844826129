import { createSlice } from "@reduxjs/toolkit";
import {
  deleteCartProduct,
  getCartList,
  getHistoryOrderDetail,
  getHistoryOrderDetailBulk,
  getLogistic,
  getLogisticDetail,
  getPreCheckout,
  postCheckout,
  postPayment,
} from "./cart.api";
import { message } from "antd";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    loading: false,
    loadingPreCheckout: false,
    loadingOrderDetail: false,
    loadingLogisticDetail: false,
    error: {},
    cartList: [],
    cartInfo: {},
    isCartEmpty: true,
    orderInfoAll: {},
    orderList: [],
    logisticList: [],
    logisticDetailList: [],
    paymentMethodList: [],
    orderDetail: {},
    loadingHistoryBulk: false,

    isBluePrint: false,
    Note: [],
    ChoosedAddressArr: [],
    ChoosedLogistic: [],
    ChoosedLogisticDetail: [],
  },
  reducers: {
    handleChoosedLogisticDetail: (state, { payload }) => {
      state.ChoosedLogisticDetail = payload;
    },

    handleChoosedLogistic: (state, { payload }) => {
      state.ChoosedLogistic = payload;
    },

    handleChoosedAddress: (state, { payload }) => {
      state.ChoosedAddressArr = payload;
      // console.log(payload.pickId)
    },

    handleNote: (state, { payload }) => {
      state.Note = payload;
    },

    handleBluePrint: (state, { payload }) => {
      state.isBluePrint = payload;
    },

    handleResetLogisticById: (state, { payload }) => {
      const id = payload;
      const remapLog = state.ChoosedLogistic.map((l) =>
        l.id === id ? { ...l, logistic: null } : l,
      );
      const remapLogDet = state.ChoosedLogisticDetail.map((ld) =>
        ld.id === id ? { ...ld, logisticDetail: null } : ld,
      );
      state.ChoosedLogistic = remapLog;
      state.ChoosedLogisticDetail = remapLogDet;
    },
  },
  extraReducers: {
    [getCartList.pending]: (state, action) => {
      state.loading = true;
    },
    [getCartList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.listProduct = payload.message.items
      // state.listCategory = false
      state.cartInfo = payload.cartItem;
      state.cartList = payload.cartItem.items;
      state.isCartEmpty = false;
      console.log(payload, "cart list");
    },
    [getCartList.rejected]: (state, action) => {
      console.log("rejected 500 cart list");
      state.loading = false;
      state.cartList = [];
      state.isCartEmpty = true;
      state.cartInfo = {};
    },
    //delete
    [deleteCartProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCartProduct.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [deleteCartProduct.rejected]: (state, action) => {
      state.loading = false;
    },

    //precheck
    [getPreCheckout.pending]: (state, action) => {
      state.loadingPreCheckout = true;
    },
    [getPreCheckout.fulfilled]: (state, { payload }) => {
      state.loadingPreCheckout = false;
      state.orderInfoAll = payload.orderDetail;
      state.orderList = payload.orderDetail.orders;
      state.paymentMethodList = payload.orderDetail.paymentMethod;
      // const obj = {
      //     "productId": 791211,
      //     "productName": "KINTAKUN BABY SET + COMFORTER Neo Kaori (KINKB57945BBC)",
      //     "quantity": 1,
      //     "price": 240000,
      //     "specialPrice": 0,
      //     "subTotalPrice": 240000,
      //     "width": 26,
      //     "length": 41,
      //     "height": 3,
      //     "weight": 1000,
      //     "plu": "854770",
      //     "sku": "854770",
      //     "isGroceries": 0,
      //     "sellerId": 2841321,
      //     "senderId": 0,
      //     "specialPricePromo": 0,
      //     "productBulkyPromo": 0,
      //     "marginSOPromo": 0
      // }
      // const itemdupl = [...payload.orderDetail.orders]
      // itemdupl[0].items = [obj, { ...obj, quantity: 4 }]
      // const dupl = [...payload.orderDetail.orders, ...itemdupl]
      // state.orderList = dupl//duplicated for test

      console.log(payload.orderDetail.orders, "orders");
    },
    [getPreCheckout.rejected]: (state, action) => {
      state.loadingPreCheckout = false;
    },

    //logistic list
    [getLogistic.pending]: (state, action) => {
      state.loading = true;
    },
    [getLogistic.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.logisticList = payload.company;
      // console.log(payload, 'logistic')
    },
    [getLogistic.rejected]: (state, action) => {
      state.loading = false;
    },

    //logistic detail
    [getLogisticDetail.pending]: (state, action) => {
      state.loadingLogisticDetail = true;
    },
    [getLogisticDetail.fulfilled]: (state, { payload }) => {
      state.loadingLogisticDetail = false;
      if (payload?.status?.code === "21") {
        state.logisticDetailList = [{ message: payload.status.message }];
      } else {
        state.logisticDetailList =
          payload?.logisticRate?.logisticRateList || [];
      }
    },
    [getLogisticDetail.rejected]: (state, action) => {
      state.loadingLogisticDetail = false;
      state.logisticDetailList = [];
    },
    //post checkout
    [postCheckout.pending]: (state, action) => {
      state.loading = true;
    },
    [postCheckout.fulfilled]: (state, { payload }) => {
      state.loading = false;
      console.log(payload, "POST CHECKOUT");
    },
    [postCheckout.rejected]: (state, action) => {
      state.loading = false;
    },
    //post payment
    [postPayment.pending]: (state, action) => {
      state.loading = true;
    },
    [postPayment.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [postPayment.rejected]: (state, action) => {
      state.loading = false;
    },
    //ordder detail
    [getHistoryOrderDetail.pending]: (state, action) => {
      state.loadingOrderDetail = true;
    },
    [getHistoryOrderDetail.fulfilled]: (state, { payload }) => {
      state.loadingOrderDetail = false;
      console.log(payload, "getHistoryOrderDetail");
      state.orderDetail = payload.orderDetail;
    },
    [getHistoryOrderDetail.rejected]: (state, action) => {
      state.loadingOrderDetail = false;
    },
    [getHistoryOrderDetailBulk.pending]: (state, action) => {
      state.loadingHistoryBulk = true;
    },
    [getHistoryOrderDetailBulk.fulfilled]: (state, { payload }) => {
      state.loadingHistoryBulk = false;
    },
    [getHistoryOrderDetailBulk.rejected]: (state, action) => {
      state.loadingHistoryBulk = false;
    },
  },
});

export const {
  handleChoosedAddress,
  handleChoosedLogistic,
  handleBluePrint,
  handleNote,
  handleChoosedLogisticDetail,
  handleResetLogisticById,
} = cartSlice.actions;

export default cartSlice.reducer;
