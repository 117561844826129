import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LayoutApp from "../../shared/components/LayoutApp";
import { rupiahFormat } from "../../../app/helper";
import { getHistoryOrderDetail } from "../../cart/cart.api";
import CreateAddressForm from "../components/CreateAddressForm";
import CustomBackHeader from "../../shared/components/CustomBackHeader";
import copyImage from "../../../img/profile/copyImage.svg";
import dataStatus from "../../../app/dataStatus";
import Loading from "../../shared/components/Loading";
import { message } from "antd";
import { getImageBulk } from "../../shared/shared.api";
import { unwrapResult } from "@reduxjs/toolkit";

export default function InvoiceContainer() {
  const { storeName, orderNumber } = useParams();
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const { orderDetail, loadingOrderDetail } = useSelector(
    (state) => state.cart
  );
  useEffect(() => {
    dispatch(getHistoryOrderDetail({ orderNumber: orderNumber }));
  }, [orderNumber]);

  const {
    orderStatus,
    paymentDate,
    createDate,
    deliveries,
    totalAmountPaid,
    paymentPaidDate,
    totalDeliveryFeeFinal,
    totalDiscount,
    totalDeliveryFeeDiscount,
    totalAmount,
    totalAmountFinal,
    totalMarginSOPromo,
    paymentDescriptionIn,
    transactionId,
    customerInfo,
  } = orderDetail;

  // const [CheckoutImages, setCheckoutImages] = useState([]);

  // const handleGetImageBulk = async (arrSku) => {
  //   // const listSKU = arrSku.map((_) => _.items.map((v) => v.sku));
  //   for (const sku of arrSku) {
  //     dispatch(getImageBulk({ plu: sku }))
  //       .then(unwrapResult)
  //       .then((res) => {
  //         console.log(res?.message?.featured_image);
  //         // imgAll.push({ plu: sku, image: res?.message?.featured_image });
  //         setCheckoutImages((p) => [
  //           ...p,
  //           { plu: sku, image: res?.message?.featured_image },
  //         ]);
  //       })
  //       .catch((err) => {
  //         console.log("failed get image!");
  //       });
  //   }
  // };

  // const handleGetSKU = async () => {
  //   const allOrders = await deliveries?.order_per_seller.map((s) => s);
  //   const allSku = await allOrders
  //     .map((item) => item.deliveryItems.items)
  //     .flat();
  //   const arrSKU = allSku.map((s) => s.skuSeller);
  //   console.log(arrSKU, "all sku");
  //   handleGetImageBulk(arrSKU);
  // };

  // useEffect(() => {
  //   if (deliveries?.order_per_seller.length) {
  //     handleGetSKU();
  //   }
  // }, [orderDetail]);

  const handleStatusToText = (val) => {
    const statusString = dataStatus.find((f) => f.id === val);
    return statusString || {};
  };

  const totalTransaksi = totalAmountFinal + totalMarginSOPromo;

  const diskonBiayaKirim =
    totalAmount - totalDiscount + totalDeliveryFeeFinal - totalTransaksi;

  return (
    <LayoutApp hideBottomNavbar>
      {loadingOrderDetail && <Loading />}
      <div className="detail-transaction pt-5">
        <CustomBackHeader title="Invoice" />
        <div className="infobox bg-white px-4  py-0 mt-5 mb-4 ">
          <div className="  text-bold"> INVOICE</div>
          <p className="mb-0">{transactionId}</p>
        </div>
        <div className="infobox bg-white px-4 mb-4 py-0 ">
          <div className="  text-bold">Penjual</div>
          <p className="mb-0" style={{ textTransform: "capitalize" }}>
            {customerInfo?.firstname} {customerInfo?.lastname}
          </p>
        </div>
        <hr className="mx-4 border m-0" />

        {deliveries?.order_per_seller.map((res, i) => {
          const totalItem = res?.deliveryItems?.items
            .map((q) => q?.qtyOrdered)
            ?.reduce((a, b) => a + b, 0);

          return (
            <div className="listbox p-4 bg-white mb-2 border-bottom" key={i}>
              <div className="row mb-4">
                <div className="col">
                  <p className="mb-1 text-bold">Pesanan</p>
                  <p className="mb-1 weight-light">#{i + 1}</p>
                  <p className="mb-1 text-bold">Nama Penerima</p>
                  <p className="mb-1 weight-light">
                    {res?.deliveryAddress?.intendedReceiver}
                  </p>
                  <p className="mb-1 text-bold">Tanggal Transaksi</p>
                  <p className="mb-1 weight-light">
                    {moment(paymentDate).format("DD-MM-YYYY")}
                  </p>
                  <p className="mb-1 text-bold">Kurir</p>
                  <p className="mb-1 weight-light">
                    {res?.deliveryCompany}
                    {/* ({res?.deliveryAddress?.estimated}) */}
                  </p>
                </div>
                <div className="col">
                  <p className="mb-1 text-bold">Alamat Pengiriman</p>
                  <p className="mb-1 weight-light"></p>
                  <p className="text-gray  mb-3">
                    {/* {res?.deliveryAddress?.phone} <br /> */}
                    {res?.deliveryAddress?.address}{" "}
                    {res?.deliveryAddress?.kecamatan}{" "}
                    {res?.deliveryAddress?.kelurahan}
                    {res?.deliveryAddress?.city}{" "}
                    {res?.deliveryAddress?.kelurahan}
                  </p>
                </div>
              </div>
              <div
                className="d-flex justify-content-between py-2"
                style={{
                  borderTop: "1px solid #cd3438",
                  borderBottom: "1px solid #cd3438",
                }}>
                <p
                  className="mb-0 text-bold text-center"
                  style={{ fontSize: 12, width: "32%" }}>
                  NAMA PRODUK
                </p>
                <p
                  className="mb-0 text-bold text-center"
                  style={{ fontSize: 12, width: "10%" }}>
                  QTY
                </p>
                <p
                  className="mb-0 text-bold text-center"
                  style={{ fontSize: 12, width: "34%" }}>
                  HARGA SATUAN
                </p>
                <p
                  className="mb-0 text-bold text-center"
                  style={{ fontSize: 12, width: "24%" }}>
                  TOTAL
                </p>
              </div>

              {res?.deliveryItems?.items.map((val, j) => (
                <div className="d-flex justify-content-between py-2" key={j}>
                  <p
                    className="mb-1 weight-light text-justify"
                    style={{ fontSize: 11, width: "32%" }}>
                    {val?.name}
                  </p>
                  <p
                    className="mb-1 weight-light text-center"
                    style={{ fontSize: 11, width: "10%" }}>
                    {val?.qtyOrdered}
                  </p>
                  <p
                    className="mb-1 weight-light text-center"
                    style={{ fontSize: 11, width: "34%" }}>
                    {val?.discount ? (
                      <div className="">
                        <div className="mb-0 d-flex align-items-center justify-content-center">
                          <s
                            className=""
                            style={{
                              fontSize: 11,
                              color: "#BABABA",
                              fontWeight: "600",
                            }}>
                            {rupiahFormat(val?.defaultPrice)}
                          </s>{" "}
                          {/* <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              height: 19,
                              background: "#FDD203",
                              color: "#CD3438",
                              fontSize: 10,
                              borderRadius: 4,
                              padding: "0 4px",
                              marginLeft: 4,
                              fontWeight: 600,
                            }}>
                            -
                            {Math.round(
                              ((val?.defaultPrice -
                                (val?.defaultPrice - val?.discount)) /
                                val?.defaultPrice) *
                                100
                            )}
                            %
                          </div> */}
                        </div>
                        <div className="" style={{ fontSize: 11 }}>
                          {rupiahFormat(val?.defaultPrice - val?.discount)}
                        </div>
                      </div>
                    ) : (
                      <div className="" style={{ fontSize: 11 }}>
                        {rupiahFormat(val?.defaultPrice)}
                      </div>
                    )}
                  </p>
                  {/* //total * qty */}
                  <div
                    className="mb-1 weight-light text-end"
                    style={{ fontSize: 11, width: "24%" }}>
                    {val?.discount ? (
                      <span style={{ fontSize: 11 }}>
                        {rupiahFormat(
                          (val?.defaultPrice - val?.discount) * val?.qtyOrdered
                        )}
                      </span>
                    ) : (
                      <span style={{ fontSize: 11 }}>
                        {rupiahFormat(val?.defaultPrice * val?.qtyOrdered)}
                      </span>
                    )}
                  </div>
                </div>
              ))}

              <div className="pt-4">
                <p className="mb-1 d-flex align-items-center justify-content-between">
                  <b> TOTAL HARGA ({totalItem} BARANG)</b>

                  <b>{rupiahFormat(res?.subTotal)}</b>
                </p>
                <p className="mb-1 d-flex align-items-center justify-content-between">
                  Diskon
                  <span>- {rupiahFormat(res?.subTotalDiscount)}</span>
                </p>
                <p className="mb-1 d-flex align-items-center justify-content-between">
                  Biaya Kirim <span>{rupiahFormat(res?.deliveryFee)}</span>
                </p>
                {/* <p className="mb-1 d-flex align-items-center justify-content-between">
                  Diskon Biaya Kirim
                  <span> - {rupiahFormat(res?.deliveryFeeDiscount)}</span>
                </p> */}
                <p className="mb-1 d-flex align-items-center justify-content-between">
                  Total Biaya Kirim{" "}
                  <span>{rupiahFormat(res?.deliveryFeeFinal)}</span>
                </p>
                {/* <p className="mb-1 d-flex align-items-center justify-content-between">
                  Asuransi Pengiriman <span>Rp 2.500</span>
                </p> */}
                <p className="mb-0 d-flex align-items-center justify-content-between">
                  <b>TOTAL PEMBAYARAN</b>
                  <b>
                    {rupiahFormat(
                      res?.subTotalAmountFinal +
                        res?.subTotalDirectMargin +
                        res?.deliveryFeeFinal
                    )}
                  </b>
                </p>
              </div>
            </div>
          );
        })}

        <div className="p-4 bg-white">
          {/* <p className="mb-1 text-bold">Rincian Pembayaran </p>
          <p className="mb-3 d-flex align-items-center justify-content-between">
            Metode Pembayaran <span>{paymentDescriptionIn || "-"}</span>
          </p> */}

          <p className="mb-1 d-flex align-items-center justify-content-between">
            Subtotal <span>{rupiahFormat(totalAmount)}</span>
          </p>
          <p className="mb-1 d-flex align-items-center justify-content-between">
            Diskon <span>- {rupiahFormat(totalDiscount)}</span>
          </p>
          <p className="mb-1 d-flex align-items-center justify-content-between">
            Biaya Kirim <span>{rupiahFormat(totalDeliveryFeeFinal)}</span>
          </p>
          <p className="mb-1 d-flex align-items-center justify-content-between">
            Diskon Biaya Kirim <span>- {rupiahFormat(diskonBiayaKirim)}</span>
          </p>
          {/* <p className="mb-1 d-flex align-items-center justify-content-between">
            Asuransi Pengiriman <span>Rp 2.500</span>
          </p> */}
          {/* <p className="mb-0 pb-2 border-bottom d-flex align-items-center justify-content-between">
            Biaya Layanan <span>Rp 500</span>
          </p> */}
          <p className="my-2  d-flex align-items-center justify-content-between">
            Total{" "}
            <span>{rupiahFormat(totalAmountFinal + totalMarginSOPromo)}</span>
          </p>
          {/* <p className="mb-2 text-success text-bold d-flex align-items-center justify-content-between">
            Margin (Potongan Langsung) <span>- Rp 12.400</span>
          </p> */}
          <p className="mb-2 text-bold d-flex align-items-center justify-content-between">
            Total yang dibayar{" "}
            <span> {rupiahFormat(totalAmountFinal + totalMarginSOPromo)}</span>
          </p>
        </div>
      </div>
    </LayoutApp>
  );
}
