import axios from "axios";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { nanoid } from "nanoid";
import LayoutApp from "../../shared/components/LayoutApp";
import BannerPromotion from "../components/BannerPromotion";
import Categories from "../components/Categories";
import HeaderStore from "../components/HeaderStore";
import ProductList from "../components/ProductList";
import ProductRecommendationList from "../components/ProductRecommendationList";

export default function StoreContainer() {
  const { storeName } = useParams();
  useEffect(() => {
    // alfamind.store_owner.doctype.toko_saya.toko_saya.store_detail
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/method/alfamind.store_owner.doctype.toko_saya.toko_saya.store_detail?store_domain=${storeName}`,
      )
      .then((res) => {
        localStorage.setItem(
          "alfamindMemberId",
          res.data.message.sat_member_id,
        );
        localStorage.setItem("alfamindStoreName", res.data.message.store_name);
        localStorage.setItem("alfamindStorePhone", res.data.message.mobile_no);
        localStorage.setItem("alfamindToken", res.data.message.token);
        localStorage.setItem("alfamindId", res.data.message.user);
        if (localStorage.getItem("alfamindDeviceId")) {
          console.log("deviceid is available");
        } else {
          localStorage.setItem("alfamindDeviceId", nanoid());
        }
        if (!res.data.message.sat_member_id) {
          alert("Error : cannot get member ID");
        }
        if (!res.data.message.mobile_no) {
          alert("Error : cannot get phone number");
        }
        if (!res.data.message.token) {
          alert("Error : cannot get token");
        }
      })
      .catch((err) => {
        console.log(err.response);
        alert("Error : cannot get member ID");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutApp>
      <Helmet>
        <title>{`TokoSaya - ${localStorage.getItem(
          "alfamindStoreName",
        )}`}</title>
        <meta
          property="og:title"
          content={`Alfamind - ${localStorage.getItem("alfamindStoreName")}`}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          name="description"
          content="Alfamind mewujudkan mimpi kamu memiliki usaha ritel sendiri dan mendapatkan penghasilan tambahan dari rumah."
        />
      </Helmet>
      <div className="store">
        <HeaderStore />
        <BannerPromotion />
        <ProductList type="preview" />
        <ProductRecommendationList />
      </div>
    </LayoutApp>
  );
}
