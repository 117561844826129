import React, { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { GoKebabHorizontal } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { deleteAddress, getAddress } from "../profile.api";
import CustomDrawer from "../../shared/components/CustomDrawer";
import HeaderListAddress from "./HeaderListAddress";
import { Modal, message } from "antd";
import Loading from "../../shared/components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import {
  handleChoosedAddress,
  handleResetLogisticById,
} from "../../cart/cart.reducer";

export default function ListAddress() {
  const { storeName, pickId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { addressList, loading } = useSelector((state) => state.profile);
  const { ChoosedAddressArr } = useSelector((state) => state.cart);

  const [openDrawerAddress, setOpenDrawerAddress] = useState(false);
  const [ActiveAddress, setActiveAddress] = useState(null);

  const handlePickAddress = (res) => {
    if (pickId) {
      const pickIdInt = parseInt(pickId);
      const remap = ChoosedAddressArr.map((val) =>
        val.id === pickIdInt ? { ...val, address: res } : val
      );

      dispatch(handleChoosedAddress(remap));

      if (ChoosedAddressArr[pickIdInt]?.address?.id !== res.id) {
        console.log("alamat sama aja", pickIdInt);
        dispatch(handleResetLogisticById(pickIdInt)); // for reset choosed logistic & choosed layanan/ logisticDetail
      }

      navigate(-1);
    }
  };

  const handleDrawerAddres = (id) => {
    setOpenDrawerAddress((p) => !p);
    setActiveAddress(() => id);
  };

  const handleGetAddressList = () => {
    const params = {
      memberId: localStorage.getItem("alfamindMemberId"),
      deviceId: localStorage.getItem("alfamindDeviceId"),
    };
    dispatch(getAddress(params));
  };
  useEffect(() => {
    handleGetAddressList();
  }, []);

  const [FilteredDataAlamat, setFilteredDataAlamat] = useState([]);
  const [FilterText, setFilterText] = useState("");

  useEffect(() => {
    if (FilterText === "") {
      setFilteredDataAlamat(addressList || []);
    } else {
      const filtered = addressList?.filter((f) =>
        f?.receiverName?.toLowerCase()?.includes(FilterText?.toLowerCase())
      );
      console.log(filtered, "alamat filtered");
      setFilteredDataAlamat(filtered || []);
    }
  }, [FilterText, addressList]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
    setOpenDrawerAddress(() => false);
  };
  const handleOk = () => {
    // setIsModalOpen(false);
    const body = {
      memberAddress: {
        id: ActiveAddress.id,
      },
    };
    dispatch(deleteAddress(body))
      .then(unwrapResult)
      .then((res) => {
        message.success("Alamat dihapus");
        handleCancel();
        handleGetAddressList();
      })
      .catch((err) => message.error("Alamat gagal dihapus!"));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setActiveAddress(() => null);
  };

  return (
    <>
      <HeaderListAddress
        FilterText={FilterText}
        setFilterText={setFilterText}
      />
      {loading && <Loading />}
      <section className="wrap-list-address px-4">
        {FilteredDataAlamat.map((res, i) => (
          <div className="box pb-4" key={i}>
            <p className="text-bold d-flex align-items-center justify-content-between mb-0">
              <span
                onClick={() => handlePickAddress(res)}
                className={`${pickId && "pointer"}`}>
                {res.receiverName}
              </span>{" "}
              <GoKebabHorizontal
                className="text-red pointer"
                size={18}
                onClick={() => handleDrawerAddres(res)}
              />
            </p>
            <p
              className={`weight-light mb-1 ${pickId && "pointer"}`}
              onClick={() => handlePickAddress(res)}>
              {res.receiverPhone}
            </p>
            <p
              className={`mb-0 ${pickId && "pointer"}`}
              style={{ color: "#C5C5C5" }}
              onClick={() => handlePickAddress(res)}>
              {res.address} {res.subDistrictName} {res.districtName}{" "}
              {res.cityName} {res.provinceName}, {res.postCode}
            </p>
          </div>
        ))}

        {FilteredDataAlamat.length === 0 && !loading && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "70vh" }}>
            Tidak ada daftar alamat
          </div>
        )}

        <Modal
          title="Delete Confirmation"
          okText="Delete"
          okButtonProps={{
            className: "px-3",
            type: "danger",
          }}
          cancelButtonProps={{
            className: "px-3",
          }}
          style={{
            maxWidth: 350,
          }}
          closeIcon={() => {}}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}>
          <p>
            Are you sure want to delete <b>{ActiveAddress?.receiverName}</b>{" "}
            address?
          </p>
        </Modal>
        <CustomDrawer
          open={openDrawerAddress}
          onClose={() => handleDrawerAddres(null)}
          className="drawer-address-menu">
          <div className="pt-5 pb-4 px-3">
            <ul>
              <li
                className="border-bottom text-center py-4 text-bold pointer"
                onClick={() =>
                  navigate(`/${storeName}/profile/list-address/edit`, {
                    state: ActiveAddress,
                  })
                }>
                Ubah Alamat
              </li>
              <li
                className="border-bottom text-center py-4 text-bold pointer"
                onClick={showModal}>
                Hapus Alamat
              </li>
              <li className="border-bottom text-center py-4 text-bold pointer text-red">
                Jadikan Alamat Utama
              </li>
            </ul>
          </div>
        </CustomDrawer>
      </section>
    </>
  );
}
