import React, { useEffect, useState } from "react";
import CustomDrawer from "../../shared/components/CustomDrawer";
import DetailVariant from "./DetailVariant";
import { IoRemoveCircle, IoAddCircle } from "react-icons/io5";

import cartIcon from "../../../img/cartIcon.svg";

import { Button, message, notification } from "antd";
import PopUpTemplate from "../../shared/components/PopUpTemplate";
import { Link, useNavigate, useParams } from "react-router-dom";
import { rupiahFormat } from "../../../app/helper";
import { useDispatch, useSelector } from "react-redux";
import { postAddtoCart } from "../store.api";
import { unwrapResult } from "@reduxjs/toolkit";
import { getCartList } from "../../cart/cart.api";
import moment from "moment";

export default function DetailDrawer({ open, onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { storeName } = useParams();

  const handleLink = () => navigate(`/${storeName}/cart`, { replace: true });
  const { drawerProductData, loadingAddCart } = useSelector(
    (state) => state.store,
  );

  const [Data, setData] = useState({});
  useEffect(() => {
    if (drawerProductData?.is_group) {
      console.log("ini ada variant");
      console.log(drawerProductData?.items, "ini variant");
      setData(drawerProductData?.items[0]);
    } else {
      console.log("ini TIDAK variant");
      setData(drawerProductData);
    }
  }, [drawerProductData]);

  const { cartList } = useSelector((state) => state.cart);

  const [qty, setQty] = useState(1);

  const handleAdd = () => {
    if (Data?.stock > qty) {
      setQty((prev) => prev + 1);
    }
  };
  const handleReduce = () => {
    if (qty > 1) {
      setQty((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (!open) {
      setQty(() => 1);
    }
  }, [open]);

  const handleAddCart = () => {
    if (!localStorage.getItem("alfamindMemberId")) {
      return alert("member id not found!");
    }
    const data = {
      memberId: localStorage.getItem("alfamindMemberId"),
      applicationId: 335,
      items: [
        {
          productId: Data?.product_id,
          quantity: qty,
        },
      ],
      cartId:
        cartList.length === 0
          ? null
          : localStorage.getItem(`${storeName}AlfamindCartId`) || null,
      cartType: "EC",
    };
    dispatch(postAddtoCart(data))
      .then(unwrapResult)
      .then((res) => {
        // console.log(res);
        localStorage.setItem(`${storeName}AlfamindCartId`, res.cartItem.cartId);

        const params = {
          cartId: res.cartItem.cartId,
          applicationId: 335,
        };
        dispatch(getCartList(params));

        if (res.status.message === "SUCCESS") {
          onClose();
          notification.open({
            message: (
              <PopUpTemplate>
                <p className=" text-white title">
                  Produk berhasil dimasukkan <br /> ke dalam keranjang.
                </p>
                <p className="text-red w-50 link pointer" onClick={handleLink}>
                  Lihat Keranjang
                </p>
              </PopUpTemplate>
            ),
            style: {
              padding: 0,
              margin: 0,
              boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
            },
            duration: 3,
            placement: "bottom",
            closeIcon: () => <></>,
          });
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle result here
        message.error("Stok tidak tersedia!!");
        console.log(rejectedValueOrSerializedError);
      });
  };

  const isExpiredPromo = moment().isBetween(
    Data?.special_price_start,
    Data?.special_price_end,
  );

  return (
    <CustomDrawer onClose={onClose} open={open} className="detail-drawer">
      <div className="pt-5">
        <div className="d-flex prod px-4 mb-4">
          <img
            src={process.env.REACT_APP_IMAGE_URL + Data?.featured_image}
            alt="product"
            className="me-3"
            onError={(event) =>
              (event.target.src =
                "https://nayemdevs.com/wp-content/uploads/2020/03/default-product-image.png")
            }
          />
          <div className="wrapdesc">
            <p className="name text-bold mb-2">{Data?.product_name}</p>
            {Data?.special_price && isExpiredPromo ? (
              <div className="d-flex align-items-center">
                <div className="price text-bold text-red me-2">
                  {rupiahFormat(Data?.special_price)}
                </div>
                <div className="mb-0 d-flex align-items-center">
                  <s
                    style={{
                      fontSize: 10,
                      color: "#BABABA",
                      fontWeight: "600",
                    }}
                  >
                    {rupiahFormat(Data?.price)}
                  </s>{" "}
                  <div
                    style={{
                      height: 15,
                      background: "#FDD203",
                      color: "#CD3438",
                      fontSize: 10,
                      borderRadius: 4,
                      padding: "0 2px",
                      marginLeft: 4,
                      fontWeight: 600,
                    }}
                  >
                    -
                    {Math.round(
                      ((Data?.price - Data?.special_price) / Data?.price) * 100,
                    )}
                    %
                  </div>
                </div>
              </div>
            ) : (
              <div className="price text-bold text-red">
                {rupiahFormat(Data?.price)}
              </div>
            )}
            {/* <div className="price text-bold text-red">
              {rupiahFormat(Data?.price)}
            </div> */}
          </div>
        </div>

        <div className="px-4">
          <p className="text-bold mb-0">Jumlah</p>
          <div className="d-flex justify-content-between align-items-center">
            <span className="text-blue">Tersedia {Data?.stock}</span>
            <div className="counter d-flex justify-content-between align-items-center">
              <IoRemoveCircle
                className={`reducebutt ${qty < 2 && "disabled"}`}
                size={24}
                onClick={handleReduce}
              />
              <span className="text-bold">{qty}</span>
              <IoAddCircle
                className={`reducebutt ${qty === Data?.stock && "disabled"}`}
                size={24}
                onClick={handleAdd}
              />
            </div>
          </div>
        </div>
        {drawerProductData?.is_group === 1 ? (
          <DetailVariant
            listItem={drawerProductData?.items}
            activeId={Data.plu}
            setData={setData}
          />
        ) : null}

        <div className="py-2 px-4 d-flex justify-content-between align-items-center mt-2">
          Total Harga{" "}
          <span className="text-bold text-red">
            {Data?.special_price && isExpiredPromo
              ? rupiahFormat(Data?.special_price * qty)
              : rupiahFormat(Data?.price * qty)}
          </span>
        </div>
        <div className="p-4 wrapbutt">
          <Button
            className="bg-red text-white w-100 text-bold"
            loading={loadingAddCart}
            disabled={Data?.stock === 0}
            onClick={handleAddCart}
          >
            <img src={cartIcon} alt="cart-icon" className="me-1" />
            {Data?.stock === 0 ? "Stok Habis" : "Tambah ke Keranjang"}
          </Button>
        </div>
      </div>
    </CustomDrawer>
  );
}
