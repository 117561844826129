import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { trxIdGenerator } from "../../app/helper";

// export const postLogin = createAsyncThunk('login/postLogin', async (data, { rejectWithValue }) => {
//     try {
//         const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, data)
//         return response.data
//     } catch (error) {
//         console.log(error.response, 'error response')
//         return rejectWithValue(error)
//     }
// })

export const getProvince = createAsyncThunk('profile/getProvince', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SAT_API_URL}/user/v1/account/province/get`, {
            params: params,
            headers: {
                token: localStorage.getItem("alfamindToken"),
                trxId: trxIdGenerator(),
                id: localStorage.getItem("alfamindId"),
            }
        })
        // console.log(response.data);
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const getCity = createAsyncThunk('profile/getCity', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SAT_API_URL}/user/v1/account/city/get`, {
            params: params,
            headers: {
                token: localStorage.getItem("alfamindToken"),
                trxId: trxIdGenerator(),
                id: localStorage.getItem("alfamindId"),
            }
        })
        // console.log(response.data);
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const getDistrict = createAsyncThunk('profile/getDistrict', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SAT_API_URL}/user/v1/account/district/get`, {
            params: params,
            headers: {
                token: localStorage.getItem("alfamindToken"),
                trxId: trxIdGenerator(),
                id: localStorage.getItem("alfamindId"),
            }
        })
        // console.log(response.data);
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})


export const getSubDistrict = createAsyncThunk('profile/getSubDistrict', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SAT_API_URL}/user/v1/account/subDistrict/get`, {
            params: params,
            headers: {
                token: localStorage.getItem("alfamindToken"),
                trxId: trxIdGenerator(),
                id: localStorage.getItem("alfamindId"),
            }
        })
        // console.log(response.data);
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})


export const postAddress = createAsyncThunk('profile/postAddress', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SAT_API_URL}/user/v1/account/address/save`, data, {
            headers: {
                token: localStorage.getItem("alfamindToken"),
                trxId: trxIdGenerator(),
                id: localStorage.getItem("alfamindId"),
            }
        })
        // console.log(response.data);
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})
export const editAddress = createAsyncThunk('profile/editAddress', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SAT_API_URL}/user/v1/account/address/edit`, data, {
            headers: {
                token: localStorage.getItem("alfamindToken"),
                trxId: trxIdGenerator(),
                id: localStorage.getItem("alfamindId"),
            }
        })
        // console.log(response.data);
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})

export const deleteAddress = createAsyncThunk('profile/deleteAddress', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SAT_API_URL}/user/v1/account/address/delete`, data, {
            headers: {
                token: localStorage.getItem("alfamindToken"),
                trxId: trxIdGenerator(),
                id: localStorage.getItem("alfamindId"),
            }
        })
        // console.log(response.data);
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})


export const getAddress = createAsyncThunk('profile/getAddress', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SAT_API_URL}/user/v1/account/address/get`, {
            params: params,
            headers: {
                trxId: trxIdGenerator(),
                token: localStorage.getItem("alfamindToken"),
                id: localStorage.getItem("alfamindId")
            }
        })
        // console.log(response.data);
        return response.data
    } catch (error) {
        console.log(error.response, 'error response')
        return rejectWithValue(error)
    }
})



export const getHistoryOrder = createAsyncThunk('store/getHistoryOrder', async (params, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SAT_API_URL}/trans/v1/transactionhistory/historyOrderList/get`, {
            params: params,
            headers: {
                trxId: trxIdGenerator(),
                token: localStorage.getItem("alfamindToken"),
                id: localStorage.getItem("alfamindId")
                // id: '6283152666684@tokovirtualku.com',
                // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIxNTMwNzQyIiwic3ViIjoiNjI4MzE1MjY2NjY4NEB0b2tvdmlydHVhbGt1LmNvbSIsImlzcyI6IjE1MzA3NDIifQ.XLzawRU2eoR36M7ilpvjgV_2YBhpn-c1GU03vq_he3A'
            }
        })
        // console.log(response.data);
        return response.data
    } catch (err) {
        console.log(err.response, 'err response add to cart')
        console.log(err.status, 'err response add to cart')
        return rejectWithValue(err)
    }
})