import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LayoutApp from "../../shared/components/LayoutApp";

import { Button, Carousel } from "antd";
import axios from "axios";
import moment from "moment";
import { nanoid } from "nanoid";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { rupiahFormat, sendWhatsAppMe } from "../../../app/helper";
import Loading from "../../shared/components/Loading";
import DetailDrawer from "../components/DetailDrawer";
import DetailVariant from "../components/DetailVariant";
import HeaderDetailProduct from "../components/HeaderDetailProduct";
import { getProductDetail } from "../store.api";
import { handleDrawerProductData } from "../store.reducer";

const pattern = /src="\/files\/([^"]+)"/g;

export default function DetailProductContainer() {
  const { idProduct, storeName } = useParams();

  const handleDetailStore = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/method/alfamind.store_owner.doctype.toko_saya.toko_saya.store_detail?store_domain=${storeName}`,
      )
      .then((res) => {
        localStorage.setItem(
          "alfamindMemberId",
          res.data.message.sat_member_id,
        );
        localStorage.setItem("alfamindStoreName", res.data.message.store_name);
        localStorage.setItem("alfamindStorePhone", res.data.message.mobile_no);
        localStorage.setItem("alfamindToken", res.data.message.token);
        localStorage.setItem("alfamindId", res.data.message.user);
        if (localStorage.getItem("alfamindDeviceId")) {
          console.log("deviceid is available");
        } else {
          localStorage.setItem("alfamindDeviceId", nanoid());
        }
        if (!res.data.message.sat_member_id) {
          alert("Error : cannot get member ID");
        }
        if (!res.data.message.mobile_no) {
          alert("Error : cannot get phone number");
        }
        if (!res.data.message.token) {
          alert("Error : cannot get token");
        }
      })
      .catch((err) => {
        console.log(err.response);
        alert("Error : cannot get member ID");
      });
  };

  useEffect(() => {
    handleDetailStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = {
      plu: idProduct,
    };
    dispatch(getProductDetail(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProduct]);

  const { productDetail, loading } = useSelector((state) => state.store);

  const [Data, setData] = useState({});
  const {
    images,
    featured_image,
    product_name,
    price,
    description,
    sub_category,
    plu,
    length,
    width,
    height,
    stock,
    weight,
    special_price,
    special_price_start,
    special_price_end,
  } = Data;

  const isExpiredPromo = moment().isBetween(
    special_price_start,
    special_price_end,
  );

  const handleChatStore = () => {
    const textWA = `?text=Halo%2C%20saya%20mau%20tanya%20untuk%20produk%20*${product_name}*%20ini%3A%0A${window.location.href}`;
    sendWhatsAppMe(localStorage.getItem("alfamindStorePhone"), textWA);
  };

  const [ImageArray, setImageArray] = useState([]);
  useEffect(() => {
    if (images) {
      const mapimageURL = images?.map((r) => r.file_url);
      setImageArray(() => [featured_image, ...mapimageURL]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if (productDetail?.is_group) {
      console.log("ini ada variant");
      console.log(productDetail?.items, "ini variant");
      setData(productDetail?.items[0]);
    } else {
      console.log("ini TIDAK variant");
      console.log(productDetail, "ini TIDAK variant");
      setData(productDetail);
    }
  }, [productDetail]);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const onClose = () => {
    setIsOpenDrawer((prev) => !prev);
    dispatch(handleDrawerProductData({}));
  };
  const dispatch = useDispatch();

  const handleOpenDrawer = (val) => {
    setIsOpenDrawer(true);
    dispatch(handleDrawerProductData(Data));
  };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  const detailString = description || "";
  const [IsOpenAllDesc, setIsOpenAllDesc] = useState(false);

  const manageString = () => {
    if (IsOpenAllDesc) {
      return detailString.replaceAll(
        pattern,
        `src="${process.env.REACT_APP_IMAGE_URL}/files/$1"`,
      );
    } else {
      let limitString = detailString?.substring(0, 240);
      if (detailString?.length > 240) {
        limitString += "...";
      }
      return limitString;
    }
  };

  return (
    <LayoutApp hideBottomNavbar>
      {loading && <Loading />}
      <section className="detail-product">
        <HeaderDetailProduct />
        <div className="wrapimg">
          <Carousel afterChange={onChange} dotPosition="bottom">
            {ImageArray?.map((r, i) => (
              <div key={i}>
                <div>
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + r}
                    alt=""
                    // className=" px-4 pt-3 pb-5"
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="wraptitle">
          <p className="name">{product_name}</p>
          <p className="stock">Tersedia {stock}</p>
          {special_price && isExpiredPromo ? (
            <div className="">
              <div className="mb-0 d-flex align-items-center">
                <s
                  style={{
                    fontSize: 14,
                    color: "#BABABA",
                    fontWeight: "600",
                  }}
                >
                  {rupiahFormat(price)}
                </s>{" "}
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    height: 19,
                    background: "#FDD203",
                    color: "#CD3438",
                    fontSize: 10,
                    borderRadius: 4,
                    padding: "0 4px",
                    marginLeft: 4,
                    fontWeight: 600,
                  }}
                >
                  -{Math.round(((price - special_price) / price) * 100)}%
                </div>
              </div>
              <div className="price">{rupiahFormat(special_price)}</div>
            </div>
          ) : (
            <div className="price">{rupiahFormat(price)}</div>
          )}
        </div>

        {productDetail?.is_group === 1 ? (
          <DetailVariant
            listItem={productDetail?.items}
            activeId={Data.plu}
            setData={setData}
          />
        ) : null}

        <div className="wrapdetail px-4 py-3">
          <p className="mb-2 text-bold">Detail Produk</p>
          <p className="d-flex justify-content-between mb-1">
            Merek <span>{productDetail?.brand?.brand_name}</span>
          </p>
          <p className="d-flex justify-content-between mb-1">
            Sub Kategori
            <span className="text-bold text-red">{sub_category}</span>
          </p>
          <p className="d-flex justify-content-between mb-1">
            Kode PLU <span>{plu}</span>
          </p>
          <p className="d-flex justify-content-between mb-1">
            P x L x T (cm){" "}
            <span>
              {length} x {width} x {height}
            </span>
          </p>
          <p className="d-flex justify-content-between mb-1">
            Berat <span>{weight} Kg</span>
          </p>
          <p className="d-flex justify-content-between mb-0">
            Dikirim Dari <span>-</span>
          </p>
        </div>

        <div className="wrapdesc px-4 py-3">
          <p className="mb-2 text-bold">Detail Produk</p>
          <p
            className="html mb-0 overflow-hidden"
            dangerouslySetInnerHTML={{
              __html: manageString(),
            }}
          ></p>
          {detailString.length > 240 && (
            <p
              className="text-bold mt-3 text-red pointer"
              onClick={() => setIsOpenAllDesc((prev) => !prev)}
            >
              {IsOpenAllDesc ? (
                <>
                  Lihat lebih sedikit <FiChevronUp />
                </>
              ) : (
                <>
                  Lihat selengkapnya <FiChevronDown />
                </>
              )}
            </p>
          )}
        </div>

        <div className="wrapbuttons fixed-bottom px-4">
          <Button className="chatbut" onClick={handleChatStore}>
            Chat Toko
          </Button>
          <Button
            className="cartbut"
            onClick={handleOpenDrawer}
            disabled={stock === 0}
          >
            {stock === 0 ? "Stok Habis" : "Tambah ke Keranjang"}
          </Button>
        </div>
        <DetailDrawer open={isOpenDrawer} onClose={onClose} />
      </section>
    </LayoutApp>
  );
}
