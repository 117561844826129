import moment from "moment";
import React, { useEffect, useState } from "react";
import { rupiahFormat } from "../../../app/helper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHistoryOrder } from "../profile.api";
import { getHistoryOrderDetailBulk } from "../../cart/cart.api";
import { unwrapResult } from "@reduxjs/toolkit";
import { Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { getImageBulk } from "../../shared/shared.api";
import dataStatus from "../../../app/dataStatus";
import Loading from "../../shared/components/Loading";
import { handleLoadingProcessTransaction } from "../profile.reducer";

export default function ListTransaction() {
  const { storeName } = useParams();
  const {
    historyOrderList,
    historyOrderListTotal,
    choosedStatusFilter,
    loadingProcessTransaction,
    filterStartEndDate,
  } = useSelector((state) => state.profile);
  // const { loadingHistoryBulk } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   10 >   Pesanan dibuat
  // 11 >   Menunggu Pembayaran
  // 12 >   Pesanan sudah dibayar
  // 13 >   Pesanan diproses
  // 14 >   Pesanan dalam pengiriman
  // 15 >   Pengiriman selesai
  // 18 >  Batal
  // 112 > Gagal
  //336 > Gagal

  const [temporaryData, setTemporaryData] = useState([]);

  const [FinalData, setFinalData] = useState([]);

  const [AllSKU, setAllSKU] = useState([]);

  const handleFetchData = () => {
    dispatch(handleLoadingProcessTransaction(true));
    const lengthRow = historyOrderList.length + 10;
    const params = {
      orderBy: "EC",
      rowsNum: lengthRow,
      deviceId: localStorage.getItem("alfamindDeviceId"),
    };
    if (choosedStatusFilter !== null) {
      params.orderStatus = choosedStatusFilter;
    }
    if (filterStartEndDate.length !== 0) {
      params.startDate = filterStartEndDate[0];
      params.endDate = filterStartEndDate[1];
    }
    dispatch(getHistoryOrder(params))
      .then(unwrapResult)
      .then(() => {})
      .catch((err) => {
        console.log("errrororororo");
        dispatch(handleLoadingProcessTransaction(false));
        setTemporaryData([]);
        setFinalData([]);
        console.log("errrororororo");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleFetchData();
  }, [choosedStatusFilter, filterStartEndDate]);

  const handleMerge = async () => {
    await Promise.all(
      historyOrderList?.map(async (res) => {
        const response = await dispatch(
          getHistoryOrderDetailBulk({ orderNumber: res?.orderNumber }),
        ).then(unwrapResult);

        // console.log(response, "response");
        return response?.orderDetail;
      }),
    ).then(async (res) => {
      const skus = await res.map((r) => r.deliveries.order_per_seller).flat();
      const skus2 = await skus.map((x) => x.deliveryItems).flat();
      const skus3 = await skus2.map((x) => x.items).flat();
      const finalSku = await skus3.map((x) => x.skuSeller); //change
      await console.log(finalSku, "hasil promise");

      const merge = await historyOrderList.map((newObj) => ({
        ...newObj,
        detail: res.find((f) => f.orderNumber === newObj.orderNumber),
      }));

      await setAllSKU(() => [...new Set(finalSku)]);
      await setTemporaryData(merge);
    });

    // setTransactionDetailList(() => maphere);
  };

  useEffect(() => {
    if (historyOrderList.length !== 0) {
      handleMerge();
    }
  }, [historyOrderList]);

  useEffect(() => {
    if (AllSKU.length !== 0) {
      handleGetImageBulk();
    }
  }, [AllSKU]);

  const [AllImagesTransaction, setAllImagesTransaction] = useState([]);

  const handleGetImageBulk = async () => {
    for (const [i, sku] of AllSKU.entries()) {
      await dispatch(getImageBulk({ plu: sku }))
        .then(unwrapResult)
        .then((res) => {
          // console.log(res?.message?.featured_image);
          setAllImagesTransaction((p) => [
            ...p,
            { plu: sku, image: res?.message?.featured_image },
          ]);
        })

        .catch((err) => {
          console.log("failed get image");
        });

      await console.log("<<<<<<<<<<<<<<<");
      await console.log(i + 1);
      await console.log(AllSKU.length);
      await console.log("<<<<<<<<<<<<<<<");
      if (i === AllSKU.length - 1) {
        // setLoadingLoopImages(() => false);
        dispatch(handleLoadingProcessTransaction(false));
        setFinalData(() => temporaryData);
      }
    }
  };

  const handleClickTransaction = (status, orderNumber, expiredDate, detail) => {
    navigate(`/${storeName}/profile/list-transaction/${orderNumber}`, {
      state: { ...detail, AllImagesTransaction: AllImagesTransaction },
    });
  };

  const handleStatusToText = (val) => {
    const statusString = dataStatus.find((f) => f.id === val);
    return statusString || {};
  };
  return (
    <div className="wrap-list-transaction px-4">
      {/* {loadingLoopImages && <Loading />} */}
      {/* <pre>{JSON.stringify(AllImagesTransaction, 0, 2)}</pre> */}

      <InfiniteScroll
        dataLength={FinalData.length}
        next={handleFetchData}
        hasMore={FinalData.length < historyOrderListTotal}
        loader={
          <div className="d-flex justify-content-center align-items-center">
            <Spin /> <b className="text-red ms-3 mb-2">Loading...</b>
          </div>
        }
      >
        {FinalData?.map((res, i) => {
          const name =
            res?.detail?.deliveries?.order_per_seller[0]?.deliveryItems.items[0]
              ?.name;

          const sku =
            res?.detail?.deliveries?.order_per_seller[0]?.deliveryItems.items[0]
              ?.skuSeller;
          const prodLength =
            res?.detail?.deliveries?.order_per_seller[0]?.deliveryItems.items
              .length;
          return (
            <div
              className="box p-3 pointer"
              key={i}
              onClick={() =>
                handleClickTransaction(
                  res?.orderStatus,
                  res?.orderNumber,
                  res?.detail?.vaExpiredDate,
                  res?.detail,
                )
              }
            >
              <div className="d-flex justify-content-between mb-2">
                <div className="">
                  <p className="mb-0 date">
                    {moment(res?.createDate).format("DD MMM YYYY HH:mm")}
                  </p>
                  <p className="mb-0 id-order">{res?.orderNumber}</p>
                </div>
                <div
                  className="status px-2"
                  style={{
                    background: handleStatusToText(res?.orderStatus)?.bgColor,
                    color: handleStatusToText(res?.orderStatus)?.textColor,
                  }}
                >
                  {handleStatusToText(res?.orderStatus)?.label}
                </div>
              </div>
              <div className="d-flex mb-3">
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    AllImagesTransaction?.find((f) => f.plu === sku)?.image
                  }
                  alt=""
                  className="thumb me-2"
                />
                <div>
                  <p className="mb-1 text-bold">
                    {JSON.stringify(name).replaceAll('"', "")}
                  </p>
                  <p className="mb-0 qty">
                    {prodLength}
                    Barang
                  </p>
                </div>
              </div>
              {prodLength > 1 && (
                <p className="more mb-2 pb-2">
                  + {prodLength - 1} Barang Lainnya
                </p>
              )}
              <div className="d-flex justify-content-between ">
                <p className="text-bold mb-0">Total Transaksi</p>
                <span className="text-red text-bold">
                  {rupiahFormat(res?.amountFinal + res?.directMargin)}
                </span>
              </div>
              {/* <pre>
              {JSON.stringify(
                res.detail.deliveries.order_per_seller[0].deliveryItems.items[0]
                  ?.name,
                0,
                2
              )}
            </pre> */}
            </div>
          );
        })}
      </InfiniteScroll>
      {FinalData.length === 0 && !loadingProcessTransaction && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          Tidak ada daftar transaksi
        </div>
      )}
    </div>
  );
}
