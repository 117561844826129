import { useEffect, useState } from "react";
import CustomBackHeader from "../../shared/components/CustomBackHeader";
import LayoutApp from "../../shared/components/LayoutApp";
import Loading from "../../shared/components/Loading";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function TNCContainer() {
  const { articleName } = useParams();
  const [loadingArticle, setLoadingArticle] = useState(false);
  const [article, setArticle] = useState(null);

  const handleGetArticle = () => {
    setLoadingArticle(true);
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/method/alfamind.konten.doctype.artikel.artikel.list?filters=[["type", "=", "panduan-alfamind"], ["article_name", "=", "${decodeURIComponent(
          articleName,
        )}"]]`,
      )
      .then((res) => {
        console.log("res", res.data);
        setArticle(res.data.data[0]);
      })
      .finally(() => {
        setLoadingArticle(false);
      });
  };

  useEffect(() => {
    handleGetArticle();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutApp hideBottomNavbar>
      {loadingArticle && <Loading />}
      <CustomBackHeader title="Persetujuan Data" />
      <div className="mt-5 pt-4">
        {article && (
          <div
            className="p-4 tnc-article"
            dangerouslySetInnerHTML={{ __html: article.content }}
          />
        )}
      </div>
    </LayoutApp>
  );
}
